







































import Vue from "vue";

interface ContactLink {
  label: string;
  icon: string;
  color: string;
  darkModeColor?: string;
  link: string;
  funnyCatchPhrase: string;
}

export default Vue.extend({
  name: "Contact",
  components: {},
  data: () => ({
    links: [
      {
        label: "Email",
        icon: "mdi-email",
        color: "rgb(85, 34, 250)",
        link: "mailto:antoine.steyer@hey.com",
        funnyCatchPhrase: "Je lis mes mails quotidiennement"
      },
      {
        label: "Twitter",
        icon: "mdi-twitter",
        color: "#1DA1F2",
        link: "https://twitter.com/ant_steyer",
        funnyCatchPhrase: "Je 'tweet' plus vite que mon ombre !"
      },
      {
        label: "LinkedIn",
        icon: "mdi-linkedin",
        color: "#2867B2",
        link: "https://www.linkedin.com/in/antsteyer/",
        funnyCatchPhrase:
          "Merci de ne pas m'envoyer de message préconçu sans âme ni personalisation"
      },
      {
        label: "Github",
        icon: "mdi-github",
        color: "#24292e",
        darkModeColor: "white",
        link: "https://github.com/antsteyer",
        funnyCatchPhrase: "Mon humble contribution à l'open-source"
      }
    ] as ContactLink[]
  })
});
